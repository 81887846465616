<template>
  <div class="navbar bg-purple-600">
    <div class="container mx-auto py-5">
      <div class="block sm:flex w-full lg:w-auto items-center justify-between">
        <!-- logo -->
        <NuxtLink to="/template1/" class="text-lg ml-3 lg:ml-0">
          <NuxtImg v-if="logo" provider="directus"
                   :src="logo"
                   sizes="150px"
                   :alt="title" class="max-w-[220px] mx-auto mb-5 sm:mb-0" />
        </NuxtLink>

        <!-- socials -->
        <div class="sm:flex ">
          <div  v-for="social in socialItems"  key="index" >
            <NuxtLink :to="social.url" class="text-white dark:text-purple-400"><Icon :name="social.icon" size="40" :alt="social.name" class="mr-3"/></NuxtLink>
          </div>
        </div>
        
    
        
      </div>
      <!-- menu -->
      <div>
              
        <ul class="flex flex-col ml-5 lg:ml-0 lg:flex-row lg:gap-3">
          <li v-for="item in navs" :key="item.id">
            <NuxtLink :to="item.path" class="flex lg:px-3 py-2 text-white hover:text-gray-400">
              {{ item.name }}
            </NuxtLink>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
const { t } = useI18n()


import {useSocialStore} from "~/composables/useSocialStore";

const { logo, title, fetchGlobalData } = useGlobalStore();
const {
  fetchSocialData,
  socialItems,
  icons,
  names,
  urls
} = useSocialStore();
const { getItems } = useDirectusItems();
interface Nav {
  id:number
  name: string;
  path: string
}
const navs = ref<Nav[]>([]);
// Fetch nav  data
const fetchNavData = async () => {
  try {
    const data = await getItems<Nav>({
      collection: 'nav',
      fields: ['id','name', 'path'],
    });
    navs.value = data;

  } catch (e) {
    console.error("Failed to fetch data:", e);
  }
};

onMounted(fetchNavData);

</script>

<style scoped>
/* Add any scoped CSS here if needed */
</style>
